<template>
    <div class="bsinformation pdtHTML">
        <el-form :label-position="labelPosition" :model="formLabelAlign" class=" bor">
            <div class="flex">
                <div>
                    <el-form-item label="矿山名称与采矿人名称是否一致" label-width="220px">
                        <!-- <el-input v-model="formLabelAlign.ifSameName"></el-input> -->
                        <span><el-radio v-model="formLabelAlign.ifSameName" label="1">是</el-radio></span>
                        <span><el-radio v-model="formLabelAlign.ifSameName" label="0"
                                style="margin-left: 10px">否</el-radio></span>
                    </el-form-item>
                    <el-form-item label="矿山名称(全称)" label-width="220px">
                        <el-input v-model="formLabelAlign.mineAllName"></el-input>
                    </el-form-item>
                    <el-form-item label="中心坐标" label-width="220px">
                        <el-input v-model="formLabelAlign.centreCoordinate"></el-input>
                    </el-form-item>
                    <el-form-item label="矿山所属地区" label-width="220px">
                        <!-- <el-input v-model="formLabelAlign.type"></el-input> -->
                        <el-cascader style="justify-content: none;" v-model="province" :options="citys"
                            placeholder="请选择所属地区" :props="{
                value: 'name',
                label: 'name',
              }"></el-cascader>
                    </el-form-item>
                    <el-form-item label="矿山注册地址" label-width="220px">
                        <el-input v-model="formLabelAlign.registerAddress"></el-input>
                    </el-form-item>
                    <el-form-item label="联系人姓名" label-width="220px">
                        <el-input v-model="formLabelAlign.contactName"></el-input>
                    </el-form-item>
                    <el-form-item label="联系人手机电话" label-width="220px">
                        <el-input v-model="formLabelAlign.contactOfficeTelephone"></el-input>
                    </el-form-item>
                    <el-form-item label="电子邮箱" label-width="220px">
                        <el-input v-model="formLabelAlign.email"></el-input>
                    </el-form-item>
                    <el-form-item label="采矿权证及相关批复文件" label-width="220px">
                        <div style="display: flex;">
                            <el-upload class="upload-demo" action="#" :http-request="addinvoiceUrl" ref="uploadFileref"
                                :before-remove="beforeRemove" multiple :limit="10" :on-exceed="handleExceed"
                                :file-list="MRFileIdList">
                                <el-button size="small" type="primary">点击上传</el-button>
                                <div slot="tip" class="el-upload__tip">只能上传pdf文件，且不超过200MB</div>
                            </el-upload>
                            <!-- <div>
                                <el-button type="primary" size="small" v-if="formLabelAlign.mrfileList">查看</el-button>
                            </div> -->
                            <div style="margin-left: 10px;">
                                <el-button type="primary" size="small" v-if="urls" @click="dialog=true">查看</el-button>
                            </div>
                        </div>


                    </el-form-item>
                </div>
                <div>
                    <el-form-item label="矿井类型" label-width="220px">
                        <span><el-radio v-model="formLabelAlign.mineType" label="1">露天矿</el-radio></span>
                        <span><el-radio v-model="formLabelAlign.mineType" label="2"
                                style="margin-left: 10px">井工矿</el-radio></span>
                    </el-form-item>
                    <el-form-item label="采矿权人名称" label-width="220px">
                        <el-input v-model="formLabelAlign.mineOwnerName"></el-input>
                    </el-form-item>
                    <el-form-item label="所属集团" label-width="220px">
                        <el-input v-model="formLabelAlign.organization"></el-input>
                    </el-form-item>
                    <el-form-item label="企业类型" label-width="220px">
                        <el-select v-model="formLabelAlign.enterpriseTypeId" placeholder="请选择">
                            <el-option v-for="item in options" :key="item.dictId" :label="item.dictValue"
                                :value="item.dictId">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="企业性质" label-width="220px">
                        <el-select v-model="formLabelAlign.enterpriseNatureId" placeholder="请选择">
                            <el-option v-for="item in nature" :key="item.dictId" :label="item.dictValue"
                                :value="item.dictId">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="所属行业" label-width="220px">
                        <!-- <el-input v-model="formLabelAlign.industryId"></el-input> -->
                        <el-select v-model="formLabelAlign.industryId" placeholder="请选择">
                            <el-option v-for="item in industry" :key="item.dictId" :label="item.dictValue"
                                :value="item.dictId">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="联系人手机号码" label-width="220px">
                        <el-input v-model="formLabelAlign.contactMobilePhone"></el-input>
                    </el-form-item>
                    <el-form-item label="法定代表人姓名" label-width="220px">
                        <el-input v-model="formLabelAlign.legalName"></el-input>
                    </el-form-item>
                    <el-form-item label="国家核定产能" label-width="220px">
                        <el-input v-model="formLabelAlign.approvedCapacity"></el-input>
                    </el-form-item>
                    <el-form-item label="煤矿企宣" label-width="220px">
                        <div style="display: flex;">
                            <el-upload class="upload-demo" action="#" :http-request="addinvoiceUrls"
                                ref="uploadFileref" accept=".jpg, .jpeg, .png,.bmp,.mp4">
                                <el-button size="small" type="primary">点击上传</el-button>
                            </el-upload>
                            <div style="margin-left: 10px;">
                                <el-button type="primary" size="small" v-if="formLabelAlign.coalImgUrl"
                                    @click="dialogVisibles=true">查看</el-button>
                            </div>
                        </div>

                    </el-form-item>
                    <!-- coalImgUrl -->
                </div>
            </div>
            <!-- <el-dialog title="查看采矿权证及相关批复文件" center :visible.sync="dialog" width="30%">
                <div style="text-align: center;">
                    <el-image style="width: 100px; height: 100px" :src="urls" :preview-src-list="srcLists"></el-image>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="dialog = false">确 定</el-button>
                </span>
            </el-dialog> -->
            <el-dialog title="查看采矿权证及相关批复文件" :visible.sync="dialog" center>
                <div class="">
                    <div v-for="(item, index) in contimgs" :key="index" style="
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 20px;
              ">
                        <el-button @click="examinecontract(item.storageName)"
                            v-if="item.storageName&&item.storageName.slice(-4) == '.pdf' ||item.storageName&&item.storageName.slice(-4) == '.PDF'">点击查看文件</br></br></br>{{
                            item.originalName }}
                        </el-button>
                        <div
                            v-if="item.storageName&&item.storageName.slice(-4) == '.png'||item.storageName&&item.storageName.slice(-5) == '.jpeg' ||item.storageName&&item.storageName.slice(-4) == '.jpg'">
                            <el-image style="width: 100px; height: 100px" :src="urls"
                                :preview-src-list="srcLists"></el-image>
                        </div>
                    </div>
                </div>
            </el-dialog>



            <el-dialog title="煤矿企宣" center :visible.sync="dialogVisibles" width="30%">
                <div  style="text-align: center;" v-if="formLabelAlign.coalImgUrl&&formLabelAlign.coalImgUrl.slice(-4) == '.mp4'">
                    <video  muted
                    playsinline
                    autoplay="autoplay"
                    loop
                    preload="metadata" style="width: 200px;" :src="baseUrl + 'third/third/ossGetFile/' +formLabelAlign.coalImgUrl"></video>
                </div>
                <div style="text-align: center;" v-else>
                    <el-image style="width: 100px; height: 100px" :src="url" :preview-src-list="srcList"></el-image>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="dialogVisibles = false">确 定</el-button>
                </span>
            </el-dialog>
            <div style="text-align: right;margin: 20px 0;" v-if="istrue">
                <el-button @click="ExportInformation">导出企业信息</el-button>
                <el-button type="primary" @click="addMZ">添加煤种</el-button>
            </div>
            <div>
                <el-table :data="formLabelAlign.quality" border style="width: 100%">
                    <el-table-column prop="name" label="煤种">
                    </el-table-column>
                    <el-table-column prop="allWater" label="全水/Mt(%)">
                    </el-table-column>
                    <el-table-column prop="internalWater" label="内水/Mad(%)">
                    </el-table-column>
                    <el-table-column prop="lye" label="灰分/Ad(%)">
                    </el-table-column>
                    <el-table-column prop="volatileMatter" label="挥发分/Vdaf(%)">
                    </el-table-column>
                    <el-table-column prop="fixedCarbon" label="固定碳/FCb">
                    </el-table-column>
                    <el-table-column prop="totalSulfur" label="全硫/St,d(%)">
                    </el-table-column>
                    <el-table-column prop="netPower" label="低位发热量/大卡">
                    </el-table-column>
                    <el-table-column prop="characters" label="焦渣 特性(1-8)">
                    </el-table-column>
                    <el-table-column prop="cakingIndex" label="粘结指数(G)">
                    </el-table-column>
                    <el-table-column prop="otherIndicators" label="其他指标(-)">
                    </el-table-column>
                    <el-table-column prop="averagePrice" label="上期平均价格">
                    </el-table-column>
                    <el-table-column prop="marketValue" label="当前市场价格">
                    </el-table-column>
                </el-table>
            </div>
            <div></div>
            <div class="btn">
                <el-button type="primary" @click="addClick">提交</el-button>
            </div>
            <!-- <div style="width: 100%;height: 1px;background: #ccc;margin: 10px 0;"></div> -->
        </el-form>
        <div style="background: #f5f5f5;margin-top: 20px;">
            <div class="flexs" style="margin-top: 20px; background: #fff;padding:10px;border-radius: 10px;font-weight: bold;
            font-size: 20px;">
                <div>
                    <div>本年度累计产量（万吨）</div>
                    <div style="text-align: center;">{{this.latestCoalMineData.yearOutput}}</div>
                </div>
                <div>
                    <div>本年度累计销量（万吨）</div>
                    <div style="text-align: center;">{{this.latestCoalMineData.yearSales}}</div>
                </div>
                <div>
                    <div>当前煤矿库存量（万吨）</div>
                    <div style="text-align: center;">{{this.latestCoalMineData.stock}}</div>
                </div>
            </div>


            <div class="flex" style="margin-top: 20px;">
                <div style="width: 49%;">
                    <div class="itemInOn">年度煤矿数据</div>
                    <div class="listInOnlist">
                        <el-table :data="yearTableData" border>
                            <el-table-column prop="dateStr" label="年份" align='center'>
                            </el-table-column>
                            <el-table-column prop="output" label="产量(万吨)" align='center'>
                            </el-table-column>
                            <el-table-column prop="sales" label="销量(万吨)" align='center'>
                            </el-table-column>
                            <!-- <el-table-column prop="rfVolume" label="铁路外运量(万吨)"  align='center'  width="75px">
                            </el-table-column>
                            <el-table-column prop="hfVolume" label="公路外运量(万吨)"  align='center'  width="75px">
                            </el-table-column> -->
                            <el-table-column prop="avPrice" label="平均煤价(元/吨)" align='center'>
                            </el-table-column>
                        </el-table>
                    </div>

                </div>
                <div style="width: 49%;">
                    <div class="itemInOn">年度原煤产量及增速</div>

                    <div class="listInOn">
                        <EchartsBar1 :data="YMCLJZS" contentId="1"></EchartsBar1>
                    </div>
                </div>
            </div>

            <div style="margin-top:20px;">
                <div class="itemInOn" style="display: flex;justify-content: space-between;">
                    <div style="margin-left: 20px;margin-top: 5px;">月度价格趋势</div>
                    <el-date-picker style="margin-right: 20px;" v-model="valueLast" type="monthrange"
                        range-separator="至" start-placeholder="开始月份" end-placeholder="结束月份" value-format="yyyy-MM"
                        @change="valueLastChange">
                    </el-date-picker>
                </div>
                <div class="listInOns">
                    <EchartsLine :data="YDJGQS" contentId="2"></EchartsLine>
                </div>
            </div>
            <div style="margin-top: 20px;">
                <div class="itemInOn" style="display: flex;justify-content: space-between;">
                    <div style="margin-left: 20px;margin-top: 5px;">{{formInline.index4.yearSelect?formInline.index4.yearSelect:'2023'}}年月度煤矿数据</div>
                    <el-date-picker v-model="formInline.index4.yearSelect" type="year" value-format="yyyy"
                        @change="yearChange" placeholder="选择年" style="margin-right: 10px;">
                    </el-date-picker>
                </div>
                <div class="listInOnin">

                    <el-table :data="yearTableDatas" border>
                        <el-table-column prop="dateStr" label="月份" align='center'>
                        </el-table-column>
                        <el-table-column prop="rawMonthProduct" label="当月产量(万吨)" align='center'>
                        </el-table-column>
                        <el-table-column prop="rawProductCount" label="累计产量(万吨)" align='center'>
                        </el-table-column>
                        <el-table-column prop="marketMonthProduct" label="当月销量(万吨)" align='center'>
                        </el-table-column>
                        <el-table-column prop="marketProductCount" label="累计销量(万吨)" align='center'>
                        </el-table-column>
                        <el-table-column prop="railwayTotal" label="当月铁路外运量(万吨)" align='center'>
                        </el-table-column>
                        <el-table-column prop="railwayAccumulated" label="累计铁路外运量(万吨)" align='center'>
                        </el-table-column>
                        <el-table-column prop="highwayTotal" label="当月公路外运量(万吨)" align='center'>
                        </el-table-column>
                        <el-table-column prop="highwayAccumulated" label="累计公路外运量(万吨)" align='center'>
                        </el-table-column>
                        <el-table-column prop="transferCount" label="当月总外运量(万吨)" align='center'>
                        </el-table-column>
                        <el-table-column prop="averagePrice" label="平均煤价(元/吨)" align='center'>
                        </el-table-column>
                    </el-table>
                </div>

            </div>

            <div class="flex" style="margin-top: 20px;">
                <div style="width: 49%;">
                    <div class="itemInOn">年度累计产量分析</div>
                    <div class="listInOns">
                        <EchartsBar :data="NDLJCLFX" contentId="90"></EchartsBar>
                    </div>
                </div>
                <div style="width: 49%;">
                    <div class="itemInOn">年度累计外运量分析</div>
                    <div class="listInOns">
                        <EchartsBar :data="NDWYLFX" contentId="91"></EchartsBar>
                    </div>
                </div>
            </div>

            <div style="margin-top: 20px;" v-if="MXKCJ">
                <!-- 底部line -->
                <div class="itemInOn">煤矿日产量/销量/库存/价格趋势分析</div>
                <div class="listInOns">
                    <EchartsLine1 :data="MXKCJ" contentId="22"></EchartsLine1>
                </div>
            </div>
        </div>




        <el-dialog title="添加煤种" width="40%" :visible.sync="dialogVisible">
            <div>
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
                    <div class="flex">
                        <el-form-item label="煤种" prop="name">
                            <el-input v-model="ruleForm.name"></el-input>
                        </el-form-item>
                        <el-form-item label="全水/Mt(%)" prop="allWater">
                            <el-input v-model="ruleForm.allWater"></el-input>
                        </el-form-item>
                    </div>
                    <div class="flex">
                        <el-form-item label="内水/Mad(%)" prop="internalWater">
                            <el-input v-model="ruleForm.internalWater"></el-input>
                        </el-form-item>
                        <el-form-item label="灰分/Ad(%)" prop="lye">
                            <el-input v-model="ruleForm.lye"></el-input>
                        </el-form-item>
                    </div>
                    <div class="flex">
                        <el-form-item label="挥发分/Vdaf(%)" prop="volatileMatter">
                            <el-input v-model="ruleForm.volatileMatter"></el-input>
                        </el-form-item>
                        <el-form-item label="固定碳/FCb" prop="fixedCarbon">
                            <el-input v-model="ruleForm.fixedCarbon"></el-input>
                        </el-form-item>
                    </div>

                    <div class="flex">
                        <el-form-item label="全硫/St,d(%)" prop="totalSulfur">
                            <el-input v-model="ruleForm.totalSulfur"></el-input>
                        </el-form-item>
                        <el-form-item label="低位发热量/大卡" prop="netPower">
                            <el-input v-model="ruleForm.netPower"></el-input>
                        </el-form-item>
                    </div>
                    <div class="flex">
                        <el-form-item label="焦渣 特性(1-8)">
                            <el-input v-model="ruleForm.characters"></el-input>
                        </el-form-item>
                        <el-form-item label="粘结指数(G)">
                            <el-input v-model="ruleForm.cakingIndex"></el-input>
                        </el-form-item>
                    </div>
                    <div class="flex">
                        <el-form-item label="其他指标(-)">
                            <el-input v-model="ruleForm.otherIndicators"></el-input>
                        </el-form-item>
                        <el-form-item label="上期平均价格" prop="averagePrice">
                            <el-input v-model="ruleForm.averagePrice"></el-input>
                        </el-form-item>
                    </div>
                    <div class="flex">
                        <el-form-item label="当前市场价格">
                            <el-input v-model="ruleForm.marketValue"></el-input>
                        </el-form-item>
                        <div></div>
                    </div>

                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="addAialogVisible('ruleForm')">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import {
  getTreeV2,
  getSysDictDataByByDictType,
  detailMiningAuthInfo,
  applyMiningAuthInfo,
  ossUploadFile2,
  pageInfo,
} from "@/api/Medium.js";
const { baseUrl } = require("@/config");
import { getPdf, getPdfs } from "@/utils/exportPdf";
export default {
  data() {
    return {
      valueLast: "",
      contimgs: [],
      dialog: false,
      urls: "",
      srcLists: [],
      value1: "",
      NDLJCLFX: {},
      NDWYLFX: {},
      MXKCJ: {},
      YDJGQS: {},
      YMCLJZS: {},
      yearTableDatas: [],
      yearTableData: [],
      latestCoalMineData: {},
      baseUrl: baseUrl,
      dialogVisibles: false,
      url: "",
      srcList: [],
      province: [],
      ruleForm: {
        name: "",
        allWater: "",
        internalWater: "",
        lye: "",
        volatileMatter: "",
        fixedCarbon: "",
        totalSulfur: "",
        netPower: "",
        characters: "",
        cakingIndex: "",
        otherIndicators: "",
        averagePrice: "",
        marketValue: "",
      },
      rules: {
        name: [{ required: true, message: "请输入煤种", trigger: "blur" }],
        allWater: [{ required: true, message: "请输入全水", trigger: "blur" }],
        internalWater: [
          { required: true, message: "请输入内水", trigger: "blur" },
        ],
        lye: [{ required: true, message: "请输入灰分", trigger: "blur" }],
        volatileMatter: [
          { required: true, message: "请输入挥发分", trigger: "blur" },
        ],
        fixedCarbon: [
          { required: true, message: "请输入固定碳", trigger: "blur" },
        ],
        totalSulfur: [
          { required: true, message: "请输入全硫", trigger: "blur" },
        ],
        characters: [
          { required: true, message: "请输入焦渣 特性", trigger: "blur" },
        ],
        cakingIndex: [
          { required: true, message: "请输入粘结指数", trigger: "blur" },
        ],
        otherIndicators: [
          { required: true, message: "请输入其他指标", trigger: "blur" },
        ],
        averagePrice: [
          { required: true, message: "请输入上期平均价格", trigger: "blur" },
        ],
        marketValue: [
          { required: true, message: "请输入当前市场价格", trigger: "blur" },
        ],
        netPower: [
          { required: true, message: "请输入低位发热量/大卡", trigger: "blur" },
        ],
      },
      dialogVisible: false,
      options: [],
      MRFileIdList: [],
      citys: [],
      nature: [],
      list: [],
      industry: [],
      industryId: [],
      formLabelAlign: {
        ifSameName: "",
        mineType: "",
        quality: [],
      },
      tableData: [
        {
          number: "1",
        },
      ],
      labelPosition: "left",
      istrue: true,
      formInline: {
        index3: {
          startYearMonth: null,
          endYearMonth: null,
        },
        index4: {
          yearSelect: null,
        },
      },
    };
  },

  mounted() {
    this.getTree();
  },
  components: {
    EchartsBar: () => import("./components/bar1.vue"),
    EchartsBar1: () => import("./components/bar.vue"),
    EchartsLine: () => import("./components/line.vue"),
    EchartsLine1: () => import("./components/line1.vue"),
  },
  methods: {
    examinecontract(row) {
      let url = this.baseUrl + "third/third/ossGetFile/" + row;
      window.open(url);
    },
    yearChange(e) {
      console.log(e);
      if (!e) {
        this.formInline.index4.yearSelect = null;
      }
      this.getTree();
    },
    valueLastChange(e) {
      let _this = this;
      if (e) {
        this.$nextTick(() => {
          _this.formInline.index3.startYearMonth = e[0];
          _this.formInline.index3.endYearMonth = e[1];
          this.getTree();
        });
      } else {
        _this.formInline.index3.startYearMonth = null;
        _this.formInline.index3.endYearMonth = null;
        this.getTree();
      }
    },
    ExportInformation() {
      // this.istrue = false
      setTimeout(() => {
        getPdfs("生产煤矿数据", "pdtHTML");
        this.istrue = true;
      }, 1000);
    },
    addAialogVisible(ruleForm) {
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          this.formLabelAlign.quality.push(this.ruleForm);
          this.dialogVisible = false;
        } else {
          return false;
        }
      });
    },
    addMZ() {
      this.ruleForm = {
        name: "",
        allWater: "",
        internalWater: "",
        lye: "",
        volatileMatter: "",
        fixedCarbon: "",
        totalSulfur: "",
        netPower: "",
        characters: "",
        cakingIndex: "",
        otherIndicators: "",
        averagePrice: "",
        marketValue: "",
      };
      this.dialogVisible = true;
    },
    echange(e) {
      console.log(e);
    },
    // 提交
    addClick() {
      console.log(this.province);
      this.formLabelAlign.province = this.province[0];
      this.formLabelAlign.city = this.province[1];
      this.formLabelAlign.area = this.province[2];
      this.formLabelAlign.ifMine = "";
      console.log(this.formLabelAlign);
      applyMiningAuthInfo(this.formLabelAlign).then((res) => {
        if (res.code == 0) {
          console.log();
          // console.log('提交成功')
          this.$message({
            message: "提交成功",
            type: "success",
          });
        }
      });
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 10 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    addinvoiceUrls(file) {
      let _this = this;
      let otherFiles = file.file;
      var formData = new FormData();
      // 文件对象
      formData.append("file", otherFiles);
      ossUploadFile2(formData).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "上传成功",
            type: "success",
          });
          // _this.list.push(res.data.fileId)

          _this.formLabelAlign.coalImgUrl = res.data.storageName;
          console.log(_this.list);
        }
      });
    },
    addinvoiceUrl(file) {
      let _this = this;
      let otherFiles = file.file;
      var formData = new FormData();
      // 文件对象
      formData.append("file", otherFiles);
      ossUploadFile2(formData).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "上传成功",
            type: "success",
          });
          _this.list.push(res.data.fileId);
          _this.formLabelAlign.fileIdList = _this.list;
          console.log(_this.list);
        }
      });
    },
    getTree() {
      let confi = {
        dictType: "mine_industry",
        pageNum: 1,
        pageSize: 100,
      };
      getSysDictDataByByDictType(confi).then((res) => {
        if (res.code == 0) {
          this.industry = res.data.list;
        }
      });
      getTreeV2().then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.citys = this.deleteChildren(res.data);
        }
      });
      let configs = {
        dictType: "mine_enterprise_nature",
        pageNum: 1,
        pageSize: 100,
      };
      getSysDictDataByByDictType(configs).then((res) => {
        if (res.code == 0) {
          this.nature = res.data.list;
        }
      });

      // 企业类型
      let config = {
        dictType: "mine_enterprise_type",
        pageNum: 1,
        pageSize: 100,
      };
      getSysDictDataByByDictType(config).then((res) => {
        if (res.code == 0) {
          this.options = res.data.list;
        }
      });
      detailMiningAuthInfo({}).then((res) => {
        if (res.code == 0) {
          this.getpageInfo(res.data.enterpriseId);
          this.formLabelAlign = res.data;
          if (this.formLabelAlign.ifSameName >= 0) {
            this.formLabelAlign.ifSameName = res.data.ifSameName.toString();
            console.log(this.formLabelAlign.ifSameName);
          }
          if (this.formLabelAlign.mineType) {
            this.formLabelAlign.mineType = res.data.mineType.toString();
          }
          this.url =
            this.baseUrl + "third/third/ossGetFile/" + res.data.coalImgUrl;
          this.srcList[0] =
            this.baseUrl + "third/third/ossGetFile/" + res.data.coalImgUrl;
          if (res.data.mRFileList.length > 0) {
            let hlist = res.data.mRFileList;
            hlist.forEach((item) => {
              if (
                item.storageName.slice(-4) == ".png" ||
                item.storageName.slice(-4) == ".jpg" ||
                item.storageName.slice(-5) == ".jpeg" ||
                item.storageName.slice(-4) == ".PNG" ||
                item.storageName.slice(-4) == ".JPG" ||
                item.storageName.slice(-4) == ".PDF" ||
                item.storageName.slice(-4) == ".pdf"
              ) {
                this.urls =
                  this.baseUrl + "third/third/ossGetFile/" + item.storageName;
                this.srcLists.push(
                  this.baseUrl + "third/third/ossGetFile/" + item.storageName
                );
              }
            });
          }
          this.contimgs = res.data.mRFileList;
          let list = [];
          list.push(res.data.province);
          list.push(res.data.city);
          list.push(res.data.area);
          this.province = list;
        }
      });
    },
    getpageInfo(enterpriseId) {
      let onConfig = {
        enterpriseId: enterpriseId,
        pageNum: 1,
        pageSize: 10,
      };
      pageInfo({ ...onConfig, ...this.formInline }).then((res) => {
        if (res.code == 0) {
          if (res.data && res.data.topData) {
            this.latestCoalMineData = res.data.topData;
          }

          this.yearTableData = res.data.yearDataList;
          let list = res.data.chartList;
          this.yearTableDatas = res.data.monthDataList;
          list.forEach((item) => {
            if (item.index == 2) {
              this.YMCLJZS = item || {};
            }
            if (item.index == 3) {
              this.YDJGQS = item || {};
            }
            if (item.index == 4) {
              this.NDLJCLFX = item || {};
            }
            if (item.index == 5) {
              this.NDWYLFX = item || {};
            }
            if (item.index == 6) {
              this.MXKCJ = item || {};
            }
          });
        }
      });
    },
    deleteChildren(arr) {
      let childs = arr;
      for (let i = childs.length; i--; i > 0) {
        if (childs[i].children) {
          if (childs[i].children.length) {
            this.deleteChildren(childs[i].children);
          } else {
            delete childs[i].children;
          }
        }
      }
      return arr;
    },
  },
};
</script>
<style lang="scss" scoped>
.flex {
  display: flex;
  justify-content: space-between;
}

.bor {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ccc;
}

::v-deep .el-form--label-left .el-form-item__label {
  text-align: right !important;
}

::v-deep .el-table th.el-table__cell.is-leaf {
  text-align: center !important;
}

.btn {
  text-align: center;
  margin-top: 20px;
}

::v-deep .el-form-item__content {
  text-align: center;
  display: flex;
  /* justify-content: space-around; */
}

.flexs {
  display: flex;
  justify-content: space-around;
}

.itemInOn {
  text-align: center;
  background: #fff;
  font-weight: bold;
  font-size: 22px;
  padding: 20px 0;
  margin-bottom: 10px;
  border-radius: 20px;
}

.listInOn {
  background: #fff;
  border-radius: 20px;
  padding: 20px 10px;
  height: 330px;
  /* height: 100%; */
}

.listInOnlist {
  background: #fff;
  border-radius: 20px;
  padding: 20px 10px;
}

.listInOnin {
  background: #fff;
  border-radius: 20px;
  padding: 20px 10px;
  height: 100%;
}

.listInOns {
  background: #fff;
  border-radius: 20px;
  padding: 20px 10px;
  height: 450px;
}

::v-deep .el-date-editor .el-range-separator {
  width: 10%;
}
</style>